import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/architecturalRequests',
    name: 'architecturalRequests',
    component: () =>
      import(
        /* webpackChunkName: "architecturalRequests" */ '@/pages/Homeowner/architecturalRequests/List/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/architecturaltabs/:id',
    name: 'architecturaltabs',
    component: () =>
      import(
        /* webpackChunkName: "architecturalRequests" */ '@/pages/Shared/architecturalRequest/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'architecturalRequestDetails',
        name: 'architectural.architecturalRequestDetails',
        component: () =>
          import(
            /* webpackChunkName: "architecturalRequests" */ '@/pages/Shared/architecturalRequest/Details/index.vue'
          )
      },
      {
        path: 'architecturalRequestVoteDetails',
        name: 'architectural.architecturalRequestVoteDetails',
        component: () =>
          import(
            /* webpackChunkName: "architecturalRequests" */ '@/pages/Shared/architecturalRequest/tabs/voteDetails/List.vue'
          )
      },
      {
        path: 'architecturalRequestDocuments',
        name: 'architectural.architecturalRequestDocuments',
        component: () =>
          import(
            /* webpackChunkName: "architecturalRequests" */ '@/pages/Shared/architecturalRequest/tabs/documents/List.vue'
          )
      },
      {
        path: 'architecturalRequestImages',
        name: 'architectural.architecturalRequestImages',
        component: () =>
          import(
            /* webpackChunkName: "architecturalRequests" */ '@/pages/Shared/architecturalRequest/tabs/photos/List.vue'
          )
      },
      {
        path: 'architecturalRequestConversations',
        name: 'architectural.architecturalRequestConversations',
        component: () =>
          import(
            /* webpackChunkName: "architecturalRequests" */ '@/pages/Shared/architecturalRequest/tabs/conversations/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'architecturalRequestDetails'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'architecturalRequestDetails'
      }
    ]
  }
]
