/*
  import { documentCenterStore } from '@/services/DocumentCenter/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyMessage, notifyProblem, notifyError } from '@/services/notify'
import _get from 'lodash/get'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await documentCenterStore.dispatch ('getDocumentCenterList', {
      hoaId
    });
  */

  async getDocumentCenterList({}, { hoaID, governingDocumentCategoryID }) {
    console.log('in getDocumentCenterList...')
    try {
      const results = await kms.get('/GoverningDocuments/GoverningDocument/List', {
        params: {
          hoaID,
          governingDocumentCategoryID
        }
      })

      if (isDebug == true) console.debug('getDocumentCenterList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The Document Center list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getDocumentCategoryList({}) {
    console.log('in getDocumentCategoryList...')
    try {
      const results = await kms.get('/GoverningDocuments/GoverningDocumentCategory/List')

      if (isDebug == true) console.debug('getDocumentCategoryList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The Document Category list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getDocumentFile({ dispatch }, { params, done }) {
    const path = `/GoverningDocuments/GoverningDocument/GetFile`
    const result = await kms.get(path, {
      params
    })

    if (this.isDebug == true) console.debug('getDocumentFile=' + JSON.stringify(result))

    if (dispatch && dispatch != undefined) console.debug(dispatch)

    if (result) {
      console.debug(`Successfully retrieved the governing document.`)
      done({ details: result })
    } else {
      console.error('There was a problem retrieving the governing document.')
    }
  },

  async deleteGoverningDocument({ dispatch }, { governingDocumentID, done }) {
    try {
      const path = `/GoverningDocuments/GoverningDocument/${governingDocumentID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted this governing document.`)
        done()
      } else {
        notifyError('There was a problem deleting this governing document.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this governing document.`)
      console.error(exception)
    }
  },

  async addGoverningDocument(
    { dispatch },
    { hoaID, documentID, governingDocumentCategoryID, description, done }
  ) {
    try {
      const result = await kms.post(`/GoverningDocuments/GoverningDocument`, {
        hoaID,
        documentID,
        governingDocumentCategoryID,
        description
      })

      console.debug('result=' + JSON.stringify(result))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (result.governingDocumentID > 0) {
        console.debug(`Successfully added the governing document.`)
        done({ details: result })
      } else {
        console.debug('There was a problem adding the governing document.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this governing document.`)
      console.error(exception)
    }
  },

  async updateGoverningDocument({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/GoverningDocuments/GoverningDocument`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.governingDocumentID > 0) {
        notifyMessage(`Successfully updated the governing document.`)
        done()
      } else {
        notifyError('There was a problem updating this governing document.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this governing document.`)
      console.error(exception)
    }
  },

  async uploadDocument({ dispatch }, { payload = {}, done }) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/GoverningDocuments/GoverningDocument/StoreDocument?hoaID=${payload.hoaID}&governingDocumentID=${payload.governingDocumentID}&description=${payload.description}`
      const uploadedGoverningDocumentFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      console.debug('uploadDocument path=' + path)

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (isDebug == true)
        console.debug(
          'uploadDocument with payload=' + JSON.stringify(uploadedGoverningDocumentFile)
        )

      done({ returnedFile: uploadedGoverningDocumentFile })
    } catch (e) {
      notifyError(e)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const documentCenterStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
