import authGuard from '@/router/auth-guard'
import commonRoutes from '@/router/common/routes'
import Welcome from '@/pages/Welcome.vue'
import ArchitecturalRequests from './architecturalRequests'
import Violations from './violations'
import Amenities from './amenities'
import WorkOrderRequests from './workOrderRequests'
import communityCenter from './communityCenter'
import billing from './billing'
import evoting from './evoting'
import customerSupport from './customerSupport'

const routes = [
  // Routes shared across apps

  ...communityCenter,
  ...commonRoutes,
  ...Amenities,
  ...Violations,
  ...ArchitecturalRequests,
  ...WorkOrderRequests,
  ...billing,
  ...evoting,
  ...customerSupport,

  // Public Routes
  {
    path: '/',
    name: 'home',
    component: Welcome,
    beforeEnter: authGuard
  },

  // Protected Routes
  // User Login Required (w/authGuard)
  {
    beforeEnter: authGuard,
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "protected" */ '@/pages/About.vue')
  },
  {
    beforeEnter: authGuard,
    path: '/propertysettings',
    name: 'propertysettings',
    component: () =>
      import(
        /* webpackChunkName: "protected" */ '@/pages/Homeowner/accounts/PropertySettings/index.vue'
      )
  },
  {
    path: '/userSettings',
    name: 'userSettings',
    component: () =>
      import(
        /* webpackChunkName: "protected" */ '@/pages/Homeowner/accounts/userSettings/Main.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'profile',
        name: 'userSettings.profile',
        component: () =>
          import(
            /* webpackChunkName: "userSettings" */ '@/pages/Homeowner/accounts/userSettings/tabs/profile/Profile.vue'
          )
      },
      {
        path: 'accountSecurity',
        name: 'userSettings.accountSecurity',
        component: () =>
          import(
            /* webpackChunkName: "userSettings" */ '@/pages/Homeowner/accounts/userSettings/tabs/accountSecurity/AccountSecurity.vue'
          )
      },
      {
        path: 'notificationsubscriptions',
        name: 'userSettings.notificationsubscriptions',
        component: () =>
          import(
            /* webpackChunkName: "userSettings" */ '@/pages/Homeowner/accounts/userSettings/tabs/notificationSubscriptions/List.vue'
          )
      },
      {
        path: 'ho-twofactorauthentication',
        name: 'userSettings.ho-twofactorauthentication',
        component: () =>
          import(
            /* webpackChunkName: "userSettings" */ '@/pages/Homeowner/accounts/userSettings/tabs/twoFactorAuthentication/index.vue'
          )
      }
    ]
  },
  {
    beforeEnter: authGuard,
    path: '/takeSurvey/:id',
    name: 'takeSurvey',
    component: () =>
      import(
        /* webpackChunkName: "protected" */ '@/pages/Homeowner/surveys/UserTakeSurvey/Index.vue'
      )
  },
  {
    path: '/property',
    name: 'property',
    component: () =>
      import(
        /* webpackChunkName: "units" */ '@/pages/Homeowner/accounts/PropertySettings/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'contacts',
        name: 'property.contacts',
        component: () =>
          import(
            /* webpackChunkName: "property" */ '@/pages/Homeowner/accounts/properties/tabs/Contacts.vue'
          )
      },
      {
        path: 'mailingAddress',
        name: 'property.mailingAddress',
        component: () =>
          import(
            /* webpackChunkName: "property" */ '@/pages/Homeowner/accounts/properties/tabs/MailingAddress/index.vue'
          )
      },
      {
        path: 'directoryContactAdmin',
        name: 'property.directoryContactAdmin',
        component: () =>
          import(
            /* webpackChunkName: "property" */ '@/pages/Homeowner/accounts/properties/tabs/directoryContactAdmin/Main.vue'
          )
      },
      {
        path: 'mailDelivery',
        name: 'property.mailDelivery',
        component: () =>
          import(
            /* webpackChunkName: "property" */ '@/pages/Homeowner/accounts/properties/tabs/MailDelivery.vue'
          )
      },
      {
        path: 'vehicles',
        name: 'property.vehicles',
        component: () =>
          import(
            /* webpackChunkName: "property" */ '@/pages/Homeowner/accounts/properties/tabs/Vehicles.vue'
          )
      }
    ]
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () =>
      import(/* webpackChunkName: "notifications" */ '@/pages/Shared/notifications/Dashboard.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/alerts/:id',
    component: () =>
      import(/* webpackChunkName: "notifications" */ '@/pages/Shared/notifications/Dashboard.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/directoryContacts',
    name: 'directoryContacts',
    component: () =>
      import(
        /* webpackChunkName: "directoryContact" */ '@/pages/Homeowner/directoryContact/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: () =>
      import(/* webpackChunkName: "helpCenter" */ '@/pages/Homeowner/helpCenter/index.vue'),
    beforeEnter: authGuard
  },
  {
    path: '*',
    name: 'notFound',
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "protected" */ '@/pages/Homeowner/NotFound')
  },
  {
    path: '/conversations',
    name: 'conversations',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/newconversation',
    name: 'newconversation',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/newConversation/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/conversationdetail/:id',
    name: 'conversationdetail',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/detail/index.vue'
      ),
    beforeEnter: authGuard
  }
]

export default routes
