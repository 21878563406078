<template>
  <div v-if="unitReady" class="section about has-text-centered" tests-id="homeowner-welcome">
    <div class="container">
      <h1>{{ $t('home.welcome') }}</h1>
      <transition name="fade" mode="out-in" appear>
        <p v-if="authUser">
          {{ $t('home.helloUser', { name: authUser.name }) }}
        </p>
        <p v-else>{{ $t('home.notSignedIn') }}</p>
      </transition>
    </div>

    <div class="pt-5" v-if="marquees">
      <div
        class="card container"
        v-for="(marquee, index) in marquees"
        :key="index"
        :style="{
          padding: '12px',
          marginBottom: '12px',
          display: 'flex',
          justifyContent: 'space-between'
        }"
      >
        <div>
          <div class="container" v-html="marquee.announcementHtml"></div>
        </div>
      </div>
    </div>

    <div
      v-if="authUser && authUser.name && authUser.name != undefined"
      class="section about has-text-centered"
      tests-id="welcome-homeowner-dashboard"
    >
      <div class="container">
        <div class="flex flex-wrap -mx-2" :style="styles.tilesContainer">
          <Card :data="surveys" />
          <Card :data="balanceSummary" />
          <Card :data="governingDocuments" />
        </div>
      </div>
    </div>
  </div>
</template>

<script type="es6">
import { mapState, mapGetters } from 'vuex'
import { surveyHoList } from '@/services/Surveys/HomeOwner/Welcome/store'
import { bankSummary } from '@/services/Payments/HomeOwner/BankSummary/store'
import { twoFactorAuthentication } from '@/services/TwoFactorAuthentication/store'
import { documentCenterStore } from '@/services/DocumentCenter/store'
import { marqueeAnnouncementStore } from '@/services/MarqueeAnnouncements/store'
import moment from 'moment'
const today = moment()

// Components
import Card from '@/components/cards/Card'

export default  {
    data() {
      return {
        pendingPaymentsMadeList: [],
        marquees: [],
        isDebug: true,
        styles: {
          tilesContainer: {
            display: "flex",
            "flex-direction": "row",
            "flex-wrap": "wrap",
            width: "100%"
          }
        },
        surveys: {
          icon: 'communications',
          title: 'Surveys',
          subtitle: '',
          isAlternatingRowColors: true,
          list: [
          ],
          hrefList: [],
          button: {
          },
        },
        balanceSummary: {
          icon: 'communications',
          title: 'Balance Summary',
          subtitle: '',
          list: [],
          isAlternatingRowColors: false,
          balanceSummaryData: {
            currentBalance: 0,
            lastPaymentReceived: 0,
            nextAssessmentDue: 0,
            pendingPayments: 0,
            bankName: 'None',
            autoPayment: false
          },
          hrefList: [
              { title: 'Make Payment', path: '/payOutstandingBalance' },
              { title: 'View Billing Activity', path: '/billingtabs/accountActivity#tab' }
            ],
          button: {
          }
        },
        governingDocuments: {
          icon: 'communications',
          title: 'Governing Documents',
          subtitle: '',
          isAlternatingRowColors: true,
          list: [
          ],
          hrefList: [],
          button: {
          },
        }
      }
    },
  components: {
    Card
  },
  computed: {
    ...mapState({
      unitId: state => state.user.homeownerSelectedUnit.unitId,
      unitReady: state => state.user.homeownerSelectedUnit && state.user.homeownerSelectedUnit != undefined,
      ...mapGetters('user', ['authUser', 'retrieveUser','currentHoaId','currentOwnerId'])
    })
  },
  async created() {
    const fromSignIn = this.$route.query.fromsignin ? true : false

    if(fromSignIn)
    {
      const returned = await twoFactorAuthentication
      .dispatch('checkFirstUseMultiFactor')

      if(this.isDebug === true) console.debug('isPromptFirstLogin=' + returned.result )

      if(returned.result === false)
      {
        this.$router.push({
          name: 'promptmultifactor'
        })
      }
    }

    if (this.unitReady)
    {
      this.reload()
    }
  },
  watch: {
    unitId() {
      this.reload()
    },
    unitReady() {
      this.reload()
    }
  },
  methods: {
    async reload() {
      await this.loadMarquees()
      await this.loadNonCompletedSurveys()
      await this.loadBankSummary()
      await this.loadDocumentCenter()
    },
    async loadBankSummary() {
      if(this.authUser && this.currentOwnerId && this.currentOwnerId != undefined && this.unitReady && this.currentHoaId != undefined)
      {
      const params = {
        hoaID: this.currentHoaId,
        ownerID: this.currentOwnerId
      }

      await bankSummary
        .dispatch('getBalanceSummaries', {
          params
        })
        .then(({ result }) => {
          console.debug('get balance summaries=' + JSON.stringify(result))
          if(result)
          {
            this.balanceSummary.balanceSummaryData.currentBalance = result.currentBalanceAmount || 0
            this.balanceSummary.balanceSummaryData.lastPaymentReceived = result.lastPaymentAmount || 0
            this.balanceSummary.balanceSummaryData.nextAssessmentDue = result.nextAssessmentDueAmount || 0
            this.balanceSummary.balanceSummaryData.pendingPayments = result.pendingPaymentsAmount || 0
            this.balanceSummary.balanceSummaryData.autoPayment = false

            if(result.automaticWithdrawalOwnerPaymentMethod && result.automaticWithdrawalOwnerPaymentMethod != undefined)
            {
              if(result.automaticWithdrawalOwnerPaymentMethod.paymentMethodType && result.automaticWithdrawalOwnerPaymentMethod.paymentMethodType == 'Credit Card')
              {
                this.balanceSummary.balanceSummaryData.bankName = `${result.automaticWithdrawalOwnerPaymentMethod.cardType || ''} - ${result.automaticWithdrawalOwnerPaymentMethod.lastFourDigits || ''}`
                this.balanceSummary.balanceSummaryData.autoPayment = true
              } else if(result.automaticWithdrawalOwnerPaymentMethod.paymentMethodType && result.automaticWithdrawalOwnerPaymentMethod.paymentMethodType == 'Credit Card')
              {
                this.balanceSummary.balanceSummaryData.bankName = `${result.automaticWithdrawalOwnerPaymentMethod.bankName || ''} - ${result.automaticWithdrawalOwnerPaymentMethod.lastFourDigits || ''}`
                this.balanceSummary.balanceSummaryData.autoPayment = true
              }
            }
          }
        })
      }
    },

    async loadMarquees() {
        await marqueeAnnouncementStore
        .dispatch('getMarqueeAnnouncementList', {
          hoaID: this.currentHoaId,
          filterByDates: true
        })
        .then(({ list }) => {
          console.debug('marquee list=' + JSON.stringify(list) + ', date=' + today)
          if(list)
          {
            this.marquees = list
          }
        })
    },

    async loadNonCompletedSurveys() {
      if(this.authUser && this.authUser.name && this.authUser.name != undefined && this.unitReady && this.currentHoaId != undefined)
      {
        await surveyHoList
        .dispatch('getSurveyNotCompletedList', {
          hoaId: this.currentHoaId,
          showOnlyNotComplete: true
        })
        .then(({ list }) => {
          if(list)
          {
            this.surveys.hrefList = list.map(m => ({
              ...m,
              title: m.name,
              path: '/takeSurvey/' + m.surveyHeaderID
            }))
          }
        })
      }
    },

    async loadDocumentCenter() {
      if (this.isDebug == true) console.debug('in loadDocumentCenter()...')

      if(this.currentHoaId && this.currentHoaId > 0)
      {
        await documentCenterStore
        .dispatch('getDocumentCenterList', {
          hoaID: this.currentHoaId
        })
        .then(async ({ list }) => {
          if(list)
          {
            this.governingDocuments.hrefList = list.map(m => ({
              ...m,
              title: m.description,
              path: '/communityCenter/documentCenter#tab'
            }))
          }
        })
      }
    }
  }
}
</script>
