import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/evoting',
    name: 'evoting',
    component: () => import(/* webpackChunkName: "evoting" */ '@/pages/Homeowner/evoting/List.vue'),
    beforeEnter: authGuard
  }
]
