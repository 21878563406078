/*
  import { marqueeAnnouncementStore } from '@/services/MarqueeAnnouncements/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await marqueeAnnouncementStore.dispatch ('getHoaContactsList', {
      hoaId
    });
  */

  async getMarqueeAnnouncementList({}, { hoaID, filterByDates }) {
    console.log('in getMarqueeAnnouncementList...' + hoaID)

    if (hoaID !== undefined) {
      let path = `?hoaID=${hoaID}`
      if (filterByDates !== undefined && filterByDates) {
        path = path + `&filterByDates=${filterByDates}`
      }

      try {
        const results = await kms.get(`/MarqueeAnnouncements/MarqueeAnnouncement/List${path}`)

        if (isDebug == true)
          console.debug('getMarqueeAnnouncementList log=' + JSON.stringify(results))

        return {
          list: results.results
        }
      } catch (exception) {
        notifyProblem(`The marquee announcements list could not be retrieved.`)
        console.error(exception)
      }
    }

    return {
      list: []
    }
  },

  async deleteMarqueeAnnouncements({ dispatch }, { marqueeAnnouncementID, done }) {
    try {
      const path = `/MarqueeAnnouncements/MarqueeAnnouncement/${marqueeAnnouncementID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the marquee announcement.`)
        done()
      } else {
        notifyError('There was a problem deleting the marquee announcement.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting the marquee announcement.`)
      console.error(exception)
    }
  },

  async addMarqueeAnnouncement({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addMarqueeAnnouncement')
    try {
      const results = await kms.post(`/MarqueeAnnouncements/MarqueeAnnouncement`, {
        hoaID: payload.hoaID,
        announcementHtml: payload.announcementHtml,
        publishDate: payload.publishDate,
        expirationDate: payload.expirationDate
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.marqueeAnnouncementID > 0) {
        notifyMessage(`Successfully added the marquee announcement.`)
        done()
      } else {
        notifyError('There was a problem adding this marquee announcement.')
      }
    } catch (exception) {
      notifyError(`There was a problem adding this marquee announcement.`)
      console.error(exception)
    }
  },

  async updateMarqueeAnnouncement({ dispatch }, { payload = {}, done }) {
    if (isDebug == true) console.debug('...in updateMarqueeAnnouncement')
    try {
      const results = await kms.put(`/MarqueeAnnouncements/MarqueeAnnouncement`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.marqueeAnnouncementID > 0) {
        notifyMessage(`Successfully updated this marquee announcement.`)
        done()
      } else {
        notifyError('There was a problem updating this marquee announcement.')
      }
    } catch (exception) {
      notifyError(`There was a problem processing the update for this marquee announcement.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const marqueeAnnouncementStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
