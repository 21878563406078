/*
  import { bankSummary } from '@/services/Payments/HomeOwner/BankSummary/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'
import { notifyProblem } from '@/services/notify'

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await bankSummary.dispatch ('getPaymentHistoryList', {
      ownerID
    });
  */
  async getPaymentHistoryList({}, { params }) {
    try {
      const result = await kms.get(
        '/AccountsReceivable/LedgerQueries/ARTransactionListByDateRange',
        {
          params
        }
      )

      return {
        list: result.arQueryTransactions
      }
    } catch (exception) {
      notifyProblem(`The past payments history list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getPendingPaymentsMadeList({}, { params }) {
    try {
      const result = await kms.get('/Roster/OwnerElectronicPayment/List', {
        params
      })

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The pending payments made list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  //Bank Balance Summaries
  async getBalanceSummaries({}, { params }) {
    try {
      const result = await kms.get(`/AccountsReceivable/LedgerQueries/GetBalanceSummaries`, {
        params
      })

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the current balance summaries.`)
      console.error(exception)
    }

    return {
      survey: {}
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const bankSummary = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
