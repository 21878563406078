<template>
  <div :style="styles_.tile" class="w-full lg:w-1/3 p-2">
    <div :style="styles_.innerTile" class="bg-white p-10 rounded shadow">
      <div>
        <i style="width: 60px; height: 60px;" :class="`icon-${data.icon}`" />
      </div>

      <h1 class="text-base text-xl text-blue-800 font-semibold">{{ data.title }}</h1>
      <h2 class="text-base">{{ data.subtitle }}</h2>

      <div v-if="data.balanceSummaryData" :style="styles_.balanceSummaryData">
        <div class="mt-16 mb-10 flex flex-wrap">
          <div class="w-full border-gray-200 mb-4" v-if="data.balanceSummaryData.currentBalance">
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ data.balanceSummaryData.currentBalance | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Current Account Balance
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-4" v-else>
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ 0 | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Current Account Balance
            </h5>
          </div>
          <div
            class="w-full border-gray-200 mb-4"
            v-if="data.balanceSummaryData.lastPaymentReceived"
          >
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ data.balanceSummaryData.lastPaymentReceived | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Last Payment Received
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-4" v-else>
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ 0 | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Last Payment Received
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-4" v-if="data.balanceSummaryData.nextAssessmentDue">
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ data.balanceSummaryData.nextAssessmentDue | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Next Assessment Due
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-4" v-else>
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ 0 | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Next Assessment Due
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-4" v-if="data.balanceSummaryData.pendingPayments">
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ data.balanceSummaryData.pendingPayments | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Pending Payments
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-4" v-else>
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ 0 | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Pending Payments
            </h5>
          </div>
        </div>
        <div v-if="data.balanceSummaryData.autoPayment == true">
          <h6 class="text-base text-md text-gray-500 text-center font-normal">
            {{ `Your bank account with, "${data.balanceSummaryData.bankName || 'None'}", will be` }}
            <br />
            {{ `automatically debited for your next assessment.` }}
          </h6>
        </div>
      </div>
      <div v-if="data.assetSummary" :style="styles_.assetSummary">
        <div class="mt-16 mb-10 flex flex-wrap">
          <div class="w-full border-gray-200 mb-4" v-if="data.assetSummary.operating">
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ data.assetSummary.operating | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Operating Account
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-4" v-else>
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ 0 | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Operating Account
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-4" v-if="data.assetSummary.reserve">
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ data.assetSummary.reserve | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Reserve Accounts
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-4" v-else>
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ 0 | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Reserve Accounts
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-4" v-if="data.assetSummary.workingCapital">
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ data.assetSummary.workingCapital | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Working Capital Accounts
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-4" v-else>
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ 0 | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Working Capital Accounts
            </h5>
          </div>
        </div>
      </div>
      <div v-if="data.financialSummary" :style="styles_.financialSummary">
        <div class="mt-16 mb-0 flex flex-wrap">
          <div class="w-full border-gray-200 mb-4" v-if="data.financialSummary.operating">
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ data.financialSummary.operating | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Operating Account
            </h5>
          </div>
          <div class="w-full" v-if="data.financialSummary.reserve">
            <h5 class="text-base text-3xl text-blue-200 text-center mb-0">
              {{ data.financialSummary.reserve | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Reserve Accounts
            </h5>
          </div>
          <div class="w-full border-gray-200 mb-0" v-if="data.financialSummary.totalIncome">
            <h5 class="text-base text-3xl text-blue-800 text-center mb-0">
              {{ data.financialSummary.totalIncome | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Total Income this Year
            </h5>
          </div>
          <div class="w-full" v-if="data.financialSummary.totalExpense">
            <h5 class="text-base text-3xl text-blue-200 text-center mb-0">
              {{ data.financialSummary.totalExpense | currency }}
            </h5>
            <h5 class="text-base text-md text-gray-500 text-center font-normal">
              Total Expenses this Year
            </h5>
          </div>
        </div>

        <div class="bg-blue-900 rounded h-10" v-if="data.financialSummary.operating">
          <div
            class="bg-blue-200 flex justify-flex-end align-items-flex-end h-full rounded"
            :style="`width: ${difference_appearance}%;`"
          >
            <div
              class="bg-blue-200 border-2 border-white rounded-sm h-4 w-4 inline-block -mr-2 -mb-2 relative"
            >
              <div
                class="bg-blue-200 rounded-sm absolute p-2 w-40 text-sm z-10"
                :style="styles.textBubble"
              >
                <div :style="styles.upArrow"></div>
                {{ difference }}% to target
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul class="mt-8" v-if="Array.isArray(data.list)">
        <li
          v-for="(item, index) in data.list"
          :key="index"
          class="bg-white odd:bg-gray-200 rounded-sm p-2 flex"
          :style="styles.listItem"
        >
          <div class="w-1/5 text-blue-200 font-bold">{{ item.col1 }}</div>
          <div :style="styles.listItemRight" class="text-left">{{ item.col2 }}</div>
        </li>
      </ul>

      <div :style="styles_.dropdown" v-if="data.button.dropdown">
        <b-dropdown aria-role="list" class="outline-none focus:border-none mt-8">
          <button
            class="rounded-sm border border-gray-400 px-4 py-2"
            :style="styles.dropdownButton"
            slot="trigger"
            slot-scope="{ active }"
          >
            <span class="relative"></span>
            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            <router-link
              class="button is-info is-light is-small is-outline is-rounded"
              :to="data.button.path"
              >{{ data.button.title }}</router-link
            >
          </button>

          <span v-if="data.button.dropdown && data.button.dropdown != undefined">
            <b-dropdown-item
              v-for="(item, index) in data.button.dropdown"
              :key="index"
              aria-role="listitem"
            >
              <router-link :to="item.path">
                {{ item.title }}
              </router-link>
            </b-dropdown-item>
          </span>
        </b-dropdown>
      </div>

      <div
        class="pt-2"
        v-if="data.hrefList && data.hrefList != undefined"
        :style="{ marginLeft: '24px' }"
      >
        <ul v-if="data.isAlternatingRowColors && data.isAlternatingRowColors == true">
          <li
            class="bg-white odd:bg-gray-200 rounded-md p-2 flex"
            style="text-align:left !important;"
            v-for="(item, index) in data.hrefList"
            :key="index"
            aria-role="listitem"
          >
            <div
              class="w-1/6 text-blue-400 font-bold"
              style="text-align: center; vertical-align: center;"
            >
              {{ item.prefix }}
            </div>
            <div :style="styles.listItemRight" class="text-left href-overflow-wrap">
              <router-link :to="item.path">
                {{ item.title }}
              </router-link>
            </div>
          </li>
        </ul>
        <span v-else>
          <li
            style="text-align:left !important;"
            v-for="(item, index) in data.hrefList"
            :key="index"
            aria-role="listitem"
          >
            <router-link :to="item.path" class="font-bold href-overflow-wrap">
              {{ item.title }}
            </router-link>
          </li>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'
import _merge from 'lodash/merge'

export default {
  name: 'Card',

  props: {
    styles: {
      type: Object,
      default() {
        return {}
      }
    },

    data: {
      icon: String,
      prefix: String,
      title: String,
      subtitle: String,
      financialSummary: Object,
      assetSummary: Object,
      balanceSummaryData: Object,
      list: [],
      hrefList: [],
      isAlternatingRowColors: false,
      button: {
        title: String,
        path: String,
        dropdown: []
      }
    }
  },

  data: function() {
    // text bubble content
    var difference = '?'

    // % slider number
    var difference_appearance = 0

    if (typeof this.data.financialSummary === 'object') {
      const operating = _get(this, ['data', 'financialSummary', 'operating'], null)
      const reserve = _get(this, ['data', 'financialSummary', 'reserve'], null)

      if (typeof operating === 'number' && typeof reserve === 'number') {
        difference = ((operating / reserve) * 100).toFixed(2)
        difference_appearance = difference
      }
    }

    return {
      difference,
      difference_appearance,
      styles_: _merge(
        {},
        {
          dropdown: {
            position: 'absolute',
            bottom: '2.5rem',
            left: 0,
            width: '100%',
            justifyContent: 'center'
          },
          dropdownButton: {
            display: 'flex',
            'align-items': 'center'
          },
          financialSummary: {
            marginBottom: '0'
          },
          assetSummary: {
            marginBottom: '4rem'
          },
          listItem: {
            'align-items': 'center'
          },
          listItemRight: {
            width: '75%'
          },
          textBubble: {
            top: '20px',
            left: '-78.5px',
            color: '#FFF'
          },
          tile: {},
          innerTile: {
            position: 'relative',
            height: '100%',
            paddingBottom: '7rem'
          },
          upArrow: {
            position: 'absolute',
            top: '-5px',
            left: '50%',

            width: 0,
            height: 0,
            'border-left': '5px solid transparent',
            'border-right': '5px solid transparent',

            'border-bottom': '5px solid #2baae1'
          }
        },
        this.styles
      )
    }
  }
}
</script>
