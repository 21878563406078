<template>
  <svg
    style="margin-right: 2px;"
    xmlns="http://www.w3.org/2000/svg"
    height="30px"
    viewBox="0 0 22 20"
    width="30px"
    fill="#DAE0E9"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  }
}
</script>
