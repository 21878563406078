<template>
  <svg
    style="margin-right: 2px;"
    xmlns="http://www.w3.org/2000/svg"
    height="30px"
    viewBox="0 0 22 20"
    width="30px"
    fill="#DAE0E9"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  }
}
</script>
