import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/communityCenter/',
    component: () =>
      import(
        /* webpackChunkName: "communityCenter" */ '@/pages/Homeowner/communityCenter/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'calendar',
        name: 'communityCenter.calendar',
        component: () =>
          import(
            /* webpackChunkName: "communityCenter.calendar" */ '@/pages/Homeowner/communityCenter/tabs/calendar/index.vue'
          )
      },
      {
        path: 'documentCenter',
        name: 'communityCenter.documentCenter',
        component: () =>
          import(
            /* webpackChunkName: "communityCenter.documentCenter" */ '@/pages/Homeowner/communityCenter/tabs/documentCenter/List.vue'
          )
      },
      {
        path: '/frequentlyAskedQuestions',
        name: 'communityCenter.frequentlyAskedQuestions',
        component: () =>
          import(
            /* webpackChunkName: "communityCenter.frequentlyAskedQuestions" */

            '@/pages/Homeowner/communityCenter/tabs/frequentlyAskedQuestions/List.vue'
          ),
        beforeEnter: authGuard
      },
      {
        path: 'hoaContacts',
        name: 'communityCenter.hoaContacts',
        component: () =>
          import(
            /* webpackChunkName: "communityCenter.hoaContacts" */ '@/pages/Homeowner/communityCenter/tabs/hoaContacts/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'calendar'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'calendar'
      }
    ]
  }
]
