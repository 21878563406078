<template>
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 39 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin-right: -4px;"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="none"
      stroke-linejoin="none"
    >
      <g transform="translate(-9.000000, -609.000000)" :stroke="stroke" stroke-width="2.0">
        <g transform="translate(13.000000, 15.000000)">
          <g transform="translate(2.000000, 599.000000)">
            <path
              d="M20,8H4V6H20M20,18H4V12H20M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  }
}
</script>
