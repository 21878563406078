import authGuard from '@/router/auth-guard'

export default [
  {
    path: '/amenities',
    name: 'amenities',
    component: () => import('@/pages/Shared/amenities/Main/index.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/amenity/:id',
    name: 'amenity',
    component: () => import('@/pages/Shared/amenities/Details/index.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/amenityconversations/:id',
    name: 'amenityconversations',
    component: () => import('@/pages/Shared/amenities/Main/conversations/index.vue'),
    beforeEnter: authGuard
  }
]
