import authGuard from '@/router/auth-guard'

export default [
  {
    beforeEnter: authGuard,
    path: '/workOrderRequests',
    name: 'workOrderRequests',
    component: () =>
      import(/* webpackChunkName: "protected" */ '@/pages/Homeowner/workOrderRequest/List/Main.vue')
  },
  {
    path: '/workorderpromotetabs/:id',
    name: 'workorderpromotetabs',
    component: () =>
      import(
        /* webpackChunkName: "workOrderHoRequest" */ '@/pages/Homeowner/workOrderRequest/Details/index.vue'
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: 'workorderRequestDetail',
        name: 'workorderrequest.workorderRequestDetail',
        component: () =>
          import(
            /* webpackChunkName: "workOrderHoRequest" */ '@/pages/Homeowner/workOrderRequest/Details/tabs/requestDetails/Main.vue'
          )
      },
      {
        path: 'workorderRequestConversation',
        name: 'workorderrequest.workorderRequestConversation',
        component: () =>
          import(
            /* webpackChunkName: "workOrderHoRequest" */ '@/pages/Homeowner/workOrderRequest/Details/tabs/conversations/List.vue'
          )
      },
      {
        // If no tab is specifed, go to overview
        path: '',
        redirect: 'workorderRequestDetail'
      },
      {
        // If path doesn't match, go to overview
        path: '*',
        redirect: 'workorderRequestDetail'
      }
    ]
  }
]
