<template>
  <transition name="fade" appear>
    <div class="app-container" :style="{ width: '100vw' }">
      <sidebar
        :menu-items="sidebarMenuItems"
        v-if="$route.path !== '/promptmultifactor' && loggedIn"
      >
      </sidebar>

      <div>
        <!-- condensed sidebar for the sign in experience -->
        <DesktopSideBar v-if="!loggedIn" :menu-items="[]" />
      </div>

      <AppContent>
        <top-nav
          v-if="loggedIn && $route.path !== '/promptmultifactor'"
          :sidebar-items="sidebarMenuItems"
        />

        <Page>
          <transition class="flex-grow-2" name="fade" mode="out-in" appear>
            <router-view />
          </transition>
        </Page>
      </AppContent>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Page from '@/containers/Page'
import AppContent from '@/containers/AppContent'
import Sidebar from '@/components/Sidebar'
import TopNav from '@/components/navs/Top/index'
import DesktopSideBar from '@/components/Sidebar/components/Desktop'
import WorkOrderIcon from '@/components/icons/WorkOrder'
import ServicesIcon from '@/components/icons/Services'
import ViolationsIcon from '@/components/icons/Violations'
import Amenity2Icon from '@/components/icons/Amenity2'
import SurveyIcon from '@/components/icons/Survey'
import OpenPage from '@/components/icons/OpenPage'
import Billing from '@/components/icons/Billing'
import EvotingIcon from '@/components/icons/Evoting'
import AccountIcon from '@/components/icons/Account'
import PropertyIcon from '@/components/icons/Property'
import CustomerSupportIcon from '@/components/icons/CustomerSupport'
import HelpCenterIcon from '@/components/icons/HelpCenter'
import { windowStore } from '@/utilities/window/store'

export default {
  components: {
    DesktopSideBar,
    TopNav,
    Sidebar,
    Page,
    AppContent
  },

  data: function() {
    return {
      sidebarMenuItems: [
        {
          to: '/amenities',
          iconC: WorkOrderIcon,
          label: 'Amenities'
        },
        {
          to: '/workOrderRequests',
          iconC: Amenity2Icon,
          label: 'Work Order Requests'
        },
        {
          to: '/architecturalRequests',
          iconC: ServicesIcon,
          label: 'Architectural Requests'
        },
        {
          to: '/violations',
          iconC: ViolationsIcon,
          label: 'Violations'
        },
        {
          to: '/directoryContacts',
          iconC: OpenPage,
          label: 'Homeowner Directory'
        },
        {
          to: '/helpCenter',
          iconC: HelpCenterIcon,
          label: 'Help Center'
        },
        {
          to: '/communityCenter',
          iconC: SurveyIcon,
          label: 'Community Info'
        },
        {
          to: '/billingtabs/accountActivity#tab',
          iconC: Billing,
          label: 'Billing'
        },
        {
          to: '/evoting',
          iconC: EvotingIcon,
          label: 'eVoting'
        },
        {
          to: '/userSettings/profile#tab',
          iconC: AccountIcon,
          label: 'User Settings'
        },
        {
          to: '/property/contacts#tab',
          iconC: PropertyIcon,
          label: 'Property Settings'
        },
        {
          to: '/customerSupport',
          iconC: CustomerSupportIcon,
          label: 'Customer Support'
        }
      ]
    }
  },

  computed: {
    ...mapGetters('user', ['loggedIn'])
  },

  beforeCreate() {
    this.$store.dispatch('user/setUnitFromCookie')

    windowStore.dispatch('init')
  }
}
</script>
