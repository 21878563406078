/*
  import { surveyHoList } from '@/services/Surveys/HomeOwner/Welcome/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'
import { notifyProblem } from '@/services/notify'

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await surveyHoList.dispatch ('getSurveyNotCompletedList', {
      hoaId
    });
  */
  async getSurveyNotCompletedList({}, { hoaId, showOnlyNotComplete }) {
    try {
      const result = await kms.get('/Surveys/SurveyHeader/List', {
        params: {
          hoaId,
          showOnlyNotComplete
        }
      })

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The not completed surveys list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  //Survey Header by Id
  async getSurveyHeaderById({}, { surveyHeaderID }) {
    try {
      const survey = await kms.get(`/Surveys/SurveyHeader/${surveyHeaderID}`)

      return {
        result: survey
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the survey.`)
      console.error(exception)
    }

    return {
      survey: {}
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const surveyHoList = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
